<template>
  <div class="row p-4 p_bottom_0">
    <div class="col-md pb-4">
      <p class="text-truncate text-muted m_bottom_5">
        {{ $t("label.revenue") }}
      </p>
      <Money
        tag="h4"
        :amount="summary.revenue"
        :business-id="businessId"
        class="text-truncate m-0"
        data-test="revenue"
      />
    </div>
    <div class="col pb-4">
      <p class="text-truncate text-muted m_bottom_5">
        {{ $t("label.average_order_value") }}
      </p>
      <Money
        tag="h4"
        :amount="summary.average_revenue"
        :business-id="businessId"
        class="text-truncate m-0"
        data-test="averageRevenue"
      />
    </div>
    <div class="col pb-4">
      <p class="text-truncate text-muted m_bottom_5">
        {{ $t("label.total_orders") }}
      </p>
      <h4 class="text-truncate m-0" data-test="totalOrders">
        {{ summary.total_orders }}
      </h4>
    </div>
  </div>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  name: "Summary",
  components: {
    Money,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
  },
};
</script>
