import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";

export type IFetchBusinessActiveIntegrationResponse = {
  id: number;
  name: string;
  isEditable: boolean;
};

export class FetchBusinessActiveIntegrationError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchBusinessActiveIntegration");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchBusinessActiveIntegration(
  businessId: string
): Promise<IFetchBusinessActiveIntegrationResponse | undefined> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/api/v4/businesses/${businessId}/integrations`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchBusinessActiveIntegrationError(error);
  }

  const firstSourceFound = response.data?.data[0]?.source;

  return firstSourceFound
    ? {
        id: firstSourceFound.id,
        name: firstSourceFound.name,
        isEditable: firstSourceFound.is_editable,
      }
    : undefined;
}
