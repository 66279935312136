<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <input
          id="search"
          v-model="filters.name"
          type="text"
          data-test="name"
          class="form-control"
          autofocus="autofocus"
          :placeholder="
            $t('message.search_for_x_followed_by_ellipses', {
              search: $t('label.items_and_modifiers'),
            })
          "
        />
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <select
          id="type"
          v-model="filters.isModifier"
          class="form-control formSelect"
          data-test="type"
        >
          <option :value="null">{{ $t("label.all_types") }}</option>
          <option :value="0">{{ $t("label.item") }}</option>
          <option :value="1">{{ $t("label.modifier") }}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <button class="btn btn-outline-dark" type="submit">
          {{ $t("label.search") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { isNil } from "ramda";

export default {
  name: "Filters",
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (isNil(this.filters.isModifier)) {
      this.filters.isModifier = null;
    }
  },
};
</script>
