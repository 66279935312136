<template>
  <div class="items__actions">
    <ArticleLink tag="items" class="btn btn-rounded-light">
      <i18n path="message.learn_more_about_x">
        <span class="text-primary">{{ $t("label.items") }}</span>
      </i18n>
    </ArticleLink>
    <AtomButton
      v-if="$can('IMPORT_ITEMS')"
      data-test="import-items-btn"
      :size="AtomButtonSizeEnum.SMALL"
      :type="AtomButtonTypeEnum.SECONDARY"
      :on-click="openImportModal"
    >
      {{ $t("label.import") }}
    </AtomButton>
    <AtomButton
      v-if="showImportFromPosButton"
      data-test="import-from-pos-btn"
      :size="AtomButtonSizeEnum.SMALL"
      :type="AtomButtonTypeEnum.SECONDARY"
      :is-loading="importFromPosLoading"
      :is-disabled="importingFromPos"
      :on-click="openImportFromPosModal"
    >
      {{ importFromPosLabel }}
    </AtomButton>
    <AtomButtonLink
      :route="{ name: 'items.create' }"
      :size="AtomButtonLinkSizeEnum.SMALL"
    >
      {{ $t("label.create") }}
    </AtomButtonLink>
  </div>
</template>

<script>
import Import from "@/components/items/import/Index.vue";
import ArticleLink from "@/components/helpers/ArticleLink.vue";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomButtonLink,
  AtomButtonLinkSizeEnum,
} from "@/v2/new-design-system";
import { confirmDialog } from "@/utils/helpers/dialogs";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";
import { fetchBusinessActiveIntegration } from "@/v2/repo/fetch-business-active-integration";
import { syncOmnivoreMenu } from "@/v2/repo/sync-omnivore-menu";
import { syncRevelMenu } from "@/v2/repo/sync-revel-menu";
import { report } from "@chatfood/bug-reporter";

export default {
  name: "Actions",
  components: {
    ArticleLink,
    AtomButton,
    AtomButtonLink,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    importingFromPos: false,
    importFromPosLoading: false,
    showImportFromPosButton: false,
    posSource: undefined,
    AtomButtonSizeEnum: AtomButtonSizeEnum,
    AtomButtonTypeEnum: AtomButtonTypeEnum,
    AtomButtonLinkSizeEnum: AtomButtonLinkSizeEnum,
  }),
  computed: {
    importFromPosLabel() {
      return this.importingFromPos
        ? this.$t("label.importing")
        : this.$t("label.import_from_pos");
    },
  },
  mounted() {
    this.fetchActiveIntegration();
  },
  methods: {
    async fetchActiveIntegration() {
      try {
        const activeIntegration = await fetchBusinessActiveIntegration(
          this.businessId
        );

        if (!activeIntegration) return;

        const doesIntegrationSupportMenuPush = [
          MenuManagementSourceByIdEnum.REVEL,
          MenuManagementSourceByIdEnum.OMNIVORE,
        ].includes(activeIntegration.id);

        if (!doesIntegrationSupportMenuPush) return;

        this.posSource = activeIntegration;
        this.showImportFromPosButton = true;
      } catch (error) {
        report(error);
      }
    },
    openImportModal() {
      this.$modal.show(
        Import,
        { businessId: this.businessId },
        {
          height: "auto",
          scrollable: true,
        }
      );
    },
    openImportFromPosModal() {
      confirmDialog(this, this.importFromPos, {
        title: this.$t("message.import_from_pos_modal_title"),
        customMessage: this.$t("message.import_from_pos_modal_desc"),
      });
    },
    async importFromPos() {
      try {
        this.$modal.hide("dialog");
        this.importFromPosLoading = true;

        if (this.posSource.id === MenuManagementSourceByIdEnum.OMNIVORE) {
          await syncOmnivoreMenu({ businessId: this.businessId });
        }

        if (this.posSource.id === MenuManagementSourceByIdEnum.REVEL) {
          await syncRevelMenu({ businessId: this.businessId });
        }

        this.importingFromPos = true;

        // adding 5 minutes delay to avoid too many requests
        // as information can be available within 15 minutes
        setTimeout(() => {
          this.importingFromPos = false;
        }, 60000 * 5);
      } catch (e) {
        report(e);
      } finally {
        this.importFromPosLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.items__actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-7);
}
</style>
