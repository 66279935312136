<template>
  <tr>
    <TableSorter title="name">
      {{ $t("label.name") }}
    </TableSorter>
    <TableSorter title="displayName">
      {{ $t("label.display_name") }}
    </TableSorter>
    <TableSorter class="minw-100 maxw-100" title="selectionType">
      {{ $t("label.type") }}
    </TableSorter>
    <th class="minw-120 maxw-120">
      {{ $t("label.source") }}
    </th>
    <th class="text-center minw-120 maxw-120">
      {{ $t("label.modifiers_attached") }}
    </th>
    <TableSorter class="minw-120 maxw-120" title="minSelectedModifiers">
      {{ $t("label.min_selection") }}
    </TableSorter>
    <TableSorter class="minw-120 maxw-120" title="maxSelectedModifiers">
      {{ $t("label.max_selection") }}
    </TableSorter>
    <TableSorter class="minw-120 maxw-120" title="createdAt">
      {{ $t("label.created_at") }}
    </TableSorter>
    <th class="minw-200 maxw-200 text-center">{{ $t("label.actions") }}</th>
  </tr>
</template>

<script>
import TableSorter from "@/builders/listing/TableSorter.vue";

export default {
  name: "ListHeader",
  components: {
    TableSorter,
  },
};
</script>
