<template>
  <div class="row">
    <div class="col-md text-center text-md-left my-2">
      {{
        $tc("message.pagination_showing", source.total, {
          from: sourceFrom,
          to: sourceTo,
          total: sourceTotal,
        })
      }}
    </div>
    <div class="col-md text-center text-md-right">
      <nav class="d-inline-block">
        <ul class="pagination justify-content-center mb-0">
          <li class="page-item" :class="{ disabled: source.currentPage === 1 }">
            <a
              class="page-link"
              href="#"
              @click.prevent="nextPrev($event, source.currentPage - 1)"
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li
            v-for="page in pages"
            :key="page"
            class="page-item"
            track-by="$index"
            :class="{ active: source.currentPage === page }"
          >
            <span v-if="page === '...'" class="page-link">{{ page }}</span>
            <a
              v-if="page !== '...'"
              class="page-link"
              href="#"
              @click.prevent="navigate(page)"
              >{{ page }}
            </a>
          </li>
          <li
            class="page-item"
            :class="{ disabled: source.currentPage === source.lastPage }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="nextPrev($event, source.currentPage + 1)"
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { propOr } from "ramda";

export default {
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pages: [],
    };
  },
  computed: {
    sourceFrom() {
      return propOr(0, "from", this.source);
    },
    sourceTo() {
      return propOr(0, "to", this.source);
    },
    sourceTotal() {
      return propOr(0, "total", this.source);
    },
  },
  watch: {
    source() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    navigate(page) {
      this.$emit("navigate", page);
    },
    nextPrev(e, page) {
      if (page === 0 || page === this.source.lastPage + 1) return;

      this.navigate(page);
    },
    generatePagesArray(
      currentPage,
      collectionLength,
      rowsPerPage,
      paginationRange
    ) {
      const pages = [];
      const totalPages = Math.ceil(collectionLength / rowsPerPage);
      const halfWay = Math.ceil(paginationRange / 2);
      const ellipsesNeeded = paginationRange < totalPages;

      let position = "middle";

      if (currentPage <= halfWay) position = "start";
      else if (totalPages - halfWay < currentPage) position = "end";

      let i = 1;

      while (i <= totalPages && i <= paginationRange) {
        const pageNumber = this.calculatePageNumber(
          i,
          currentPage,
          paginationRange,
          totalPages
        );
        const openingEllipsesNeeded =
          i === 2 && (position === "middle" || position === "end");
        const closingEllipsesNeeded =
          i === paginationRange - 1 &&
          (position === "middle" || position === "start");

        if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded))
          pages.push("...");
        else pages.push(pageNumber);

        i++;
      }
      return pages;
    },
    calculatePageNumber(i, currentPage, paginationRange, totalPages) {
      const halfWay = Math.ceil(paginationRange / 2);

      if (i === paginationRange) return totalPages;
      else if (i === 1) return i;
      else if (paginationRange < totalPages)
        if (totalPages - halfWay < currentPage)
          return totalPages - paginationRange + i;
        else if (halfWay < currentPage) return currentPage - halfWay + i;
        else return i;
      else return i;
    },
    init() {
      const s = this.source;

      this.pages = this.generatePagesArray(
        s.currentPage,
        s.total,
        s.perPage,
        12
      );
    },
  },
};
</script>
