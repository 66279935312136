<template>
  <AtomHelpTooltip :content="$t('message.modifier_info')" />
</template>

<script>
import { AtomHelpTooltip } from "@/v2/design-system";

export default {
  name: "ModuleTooltip",
  components: {
    AtomHelpTooltip,
  },
};
</script>
