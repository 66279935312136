<template>
  <div class="clearfix p-4">
    <div class="row align-items-center flex-wrap mb-3">
      <div class="col title">
        <h5 class="m-0">{{ title }}</h5>
        <component :is="helpToolTipEl" v-if="hashelpToolTip" />
      </div>
      <div v-if="hasActions" class="col-auto" data-test="actions">
        <component :is="actionsEl" :business-id="businessId" />
      </div>
    </div>
    <div v-if="hasFilters" class="pl-4 pt-4 pr-4 pb-2 mb-4 border rounded">
      <form data-test="filters" @submit.prevent="filterEntries">
        <component
          :is="filtersEl"
          :business-id="businessId"
          :filters="filters"
        />
      </form>
    </div>
    <div class="border rounded">
      <component
        :is="summaryEl"
        v-if="hasSummary"
        :summary="summary"
        :business-id="businessId"
        data-test="summary"
      />
      <Table :loading="loading">
        <template slot="header">
          <component :is="tableHeader" />
        </template>
        <template slot="items">
          <component
            :is="tableLine"
            v-for="(value, index) in entries"
            :key="index"
            :entry="value"
            :business-id="businessId"
            @delete="destroyRequest"
            @refresh="fetchEntries"
          />
          <tr v-if="!entries.length">
            <td colspan="20" class="text-center">
              {{ $t("message.no_record_found") }}
            </td>
          </tr>
        </template>
      </Table>
      <div v-if="showPagination" class="p-3">
        <Paginator :source="pagination" @navigate="goToPage" />
      </div>
    </div>
  </div>
</template>

<script>
import Paginator from "@/components/helpers/Paginator.vue";
import Table from "./Table";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { propOr, is, reject, isEmpty, isNil, omit, compose } from "ramda";
import { MolGuideLink } from "@/v2/new-design-system";
import { Toast } from "@/design-system";

export default {
  name: "Entity",
  components: {
    Paginator,
    Table,
    MolGuideLink,
  },
  inject: {
    getEntries: {
      type: Function,
      required: true,
    },
    getSummary: {
      type: Function,
      default: null,
    },
    destroyItem: {
      type: Function,
      default: null,
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tableHeader: {
      type: Object,
      required: true,
    },
    tableLine: {
      type: Object,
      required: true,
    },
    filtersEl: {
      type: Object,
      default: null,
    },
    actionsEl: {
      type: Object,
      default: null,
    },
    summaryEl: {
      type: Object,
      default: null,
    },
    helpToolTipEl: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    entries: [],
    summary: {},
    pagination: {},
    filters: {},
  }),
  computed: {
    hashelpToolTip() {
      return !isNil(this.helpToolTipEl);
    },
    hasSummary() {
      return !isNil(this.summaryEl) && !isNil(this.getSummary);
    },
    hasFilters() {
      return !isNil(this.filtersEl);
    },
    hasActions() {
      return !isNil(this.actionsEl);
    },
    businessId() {
      return this.$route.params.businessId;
    },
    showPagination() {
      return !isNil(this.pagination.total);
    },
    currentPage() {
      const page = propOr(1, "page", this.$route.query);
      return parseInt(page, 10);
    },
    sortBy() {
      return propOr("", "sort", this.$route.query);
    },
  },
  watch: {
    sortBy() {
      this.fetchEntries(1);
    },
  },
  async created() {
    this.initFilters();
    this.fetchAll();
  },
  methods: {
    initFilters() {
      this.filters = omit(["sort", "page"], this.$route.query);
    },
    getParams(page) {
      if (!is(Number, page)) page = this.currentPage;

      return reject(isEmpty, {
        ...this.filters,
        page,
        sort: this.sortBy,
      });
    },
    goToPage(page) {
      this.updateQueryString({
        ...this.$route.query,
        ...{ page },
      });

      this.fetchEntries();
    },
    filterEntries() {
      this.updateQueryString({
        ...this.$route.query,
        ...this.filters,
        ...{ page: 1 },
      });

      this.fetchAll();
    },
    updateQueryString(params) {
      this.$router.replace({
        query: compose(reject(isNil), reject(isEmpty))(params),
      });
    },
    async fetchAll() {
      await Promise.all([this.fetchSummary(), this.fetchEntries()]);
    },
    async fetchEntries(page = null) {
      this.loading = true;

      try {
        const res = await this.getEntries(this.getParams(page));
        this.entries = res.data;
        this.pagination = res.pagination;
      } catch (e) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchSummary() {
      if (!this.hasSummary) return;

      const res = await this.getSummary(this.getParams());
      this.summary = res;
    },
    destroyRequest(event) {
      const payload = event.payload;
      const options = event.dialogOptions || {};

      const callback = async () => {
        try {
          await this.destroyItem(payload);
          this.entries = this.entries.filter((obj) => obj.id !== payload.id);

          new Toast().create({
            type: "success",
            text: this.$t("message.record_deleted"),
          });
        } catch (e) {
          new Toast().create({
            type: "error",
            text: this.$t("message.generic_failure"),
          });
        } finally {
          this.$modal.hide("dialog");
        }
      };

      destroyDialog(this, callback, options);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  min-width: 150px;
  margin: var(--space-7) 0;
}
</style>
