import ListingBuilder from "./ListingBuilder";

// Items
import ItemHeader from "@/components/items/listing/ListHeader.vue";
import ItemLine from "@/components/items/listing/ListLine.vue";
import ItemFilters from "@/components/items/listing/Filters.vue";
import ItemActions from "@/components/items/listing/Actions.vue";

// Modifier Lists
import ModifierListHeader from "@/components/modifier-lists/listing/ListHeader.vue";
import ModifierListLine from "@/components/modifier-lists/listing/ListLine.vue";
import ModifierListFilters from "@/components/modifier-lists/listing/Filters.vue";
import ModifierListActions from "@/components/modifier-lists/listing/Actions.vue";
import ModifierListModuleTooltip from "@/components/modifier-lists/listing/ModuleTooltip.vue";

// Order History
import OrderHistoryHeader from "@/components/order-history/listing/ListHeader.vue";
import OrderHistoryLine from "@/components/order-history/listing/ListLine.vue";
import OrderHistoryFilters from "@/components/order-history/listing/Filters.vue";
import OrderHistoryActions from "@/components/order-history/listing/Actions.vue";
import OrderHistorySummary from "@/components/order-history/listing/Summary.vue";

/** Director Class to automate listing builders */
export default class ListingDirector {
  constructor() {
    this.builder = new ListingBuilder();
  }

  /**
   * Instantiate the builder for Items.
   *
   * @returns {ListingBuilder}
   */
  _itemListing() {
    return this.builder
      .setTitle("Items and Modifiers")
      .setTableHeader(ItemHeader)
      .setTableLine(ItemLine)
      .withActions(ItemActions)
      .withFilters(ItemFilters);
  }

  /**
   * Execute the builder to render the final Items component.
   *
   * @returns {Object} Vue Component
   */
  itemListing() {
    return this._itemListing().build();
  }

  /**
   * Instantiate the builder for Modifier Lists.
   *
   * @returns {ListingBuilder}
   */
  _modifierListListing() {
    return this.builder
      .setTitle("Modifier Lists")
      .setTableHeader(ModifierListHeader)
      .setTableLine(ModifierListLine)
      .withActions(ModifierListActions)
      .withFilters(ModifierListFilters)
      .withHelpToolTip(ModifierListModuleTooltip);
  }

  /**
   * Execute the builder to render the final Modifier Lists component.
   *
   * @returns {Object} Vue Component
   */
  modifierListListing() {
    return this._modifierListListing().build();
  }

  /**
   * Instantiate the builder for Order History.
   *
   * @returns {ListingBuilder}
   */
  _orderHistoryListing() {
    return this.builder
      .setTitle("Order History")
      .setTableHeader(OrderHistoryHeader)
      .setTableLine(OrderHistoryLine)
      .withActions(OrderHistoryActions)
      .withFilters(OrderHistoryFilters)
      .withSummary(OrderHistorySummary);
  }

  /**
   * Execute the builder to render the final Order History component.
   *
   * @returns {Object} Vue Component
   */
  orderHistoryListing() {
    return this._orderHistoryListing().build();
  }
}
