<template>
  <tr>
    <td>{{ entry.name }}</td>
    <td>{{ entry.displayName }}</td>
    <td data-test="type">{{ entry.selectionType | cfStringBeautify }}</td>
    <td data-test="source">{{ entry.source ? entry.source.name : "" }}</td>
    <td class="text-center" data-test="modifiersLength">
      <span v-if="entry.itemsCount" class="badge badge-secondary">
        {{ entry.itemsCount }}
      </span>
    </td>
    <td class="text-center">{{ entry.minSelectedModifiers }}</td>
    <td class="text-center">{{ entry.maxSelectedModifiers }}</td>
    <td class="text-center">
      {{ entry.createdAt | date("DD/MM/YYYY") }}
      <small>{{ entry.createdAt | date("HH:mm:ss") }}</small>
    </td>
    <td class="text-center">
      <router-link
        :to="{
          name: 'modifier-lists.edit',
          params: { modifierListId: entry.id },
        }"
        data-test="edit"
        class="btn btn-outline-dark"
      >
        {{ $t("label.view") }}
      </router-link>
      <AtomIntegrationDisabledButton
        class="ml-2"
        kind="delete"
        :enabled="!canDelete"
        :has-menu-integration="!canDelete"
        :label="$t('label.delete').toString()"
      >
        <a
          href="#"
          data-test="delete"
          class="btn btn-outline-danger"
          @click.prevent="emitDelete"
        >
          {{ $t("label.delete") }}
        </a>
      </AtomIntegrationDisabledButton>
    </td>
  </tr>
</template>

<script>
import { date } from "@/utils/filters";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "ListLine",
  components: {
    AtomIntegrationDisabledButton,
  },
  filters: {
    date,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canDelete() {
      return (
        this.entry?.source?.isEditable &&
        this.entry?.source?.id === MenuManagementSourceByIdEnum.CHATFOOD
      );
    },
  },
  methods: {
    emitDelete() {
      if (!this.canDelete) return;

      this.$emit("delete", {
        payload: {
          id: this.entry.id,
        },
        dialogOptions: {
          resourceName: this.entry.name,
        },
      });
    },
  },
};
</script>
