<template>
  <div>
    <LoadingZone :loading="loading">
      <div class="px-4 py-2 my-2 border-bottom">
        <h4>{{ $t("c.items.import_select_file_title") }}</h4>
      </div>
      <div class="px-4">
        <SelectFile @selected="fileSelected" />
      </div>
      <div class="px-4 py-2 my-2 border-top mt-5">
        <div class="row mt-2 align-items-center">
          <div class="col-7">
            <small>
              <ArticleLink class="text-dark" tag="import_items">
                {{ $t("message.need_help_with_csv") }}
              </ArticleLink>
            </small>
          </div>
          <div class="col align-items-end text-right">
            <button class="btn btn-light" @click.prevent="close">
              {{ $t("label.cancel") }}
            </button>
            <button
              class="btn btn-primary"
              data-test-importButton
              :disabled="file === null"
              @click.prevent="importFile"
            >
              {{ $t("label.upload_file") }}
            </button>
          </div>
        </div>
      </div>
    </LoadingZone>
  </div>
</template>

<script>
import Items from "@/api/items";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import SelectFile from "./SelectFile.vue";
import ArticleLink from "@/components/helpers/ArticleLink.vue";
import { Toast } from "@/design-system";

export default {
  name: "Import",
  components: {
    SelectFile,
    LoadingZone,
    ArticleLink,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    file: null,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    fileSelected(file) {
      this.file = file;
    },
    async importFile() {
      this.loading = true;

      try {
        await Items.importFile(this.businessId, this.file);
        new Toast().create({
          type: "success",
          text: this.$t("c.items.uploading_list"),
        });
        this.close();
      } catch (err) {
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
