<template>
  <VueDatePicker
    v-model="model"
    format="MMM DD, YYYY"
    :placeholder="$t('label.select_date_range')"
    range
    no-header
    color="#292929"
    :max-date="maxDate"
    no-calendar-icon
    fullscreen-mobile
    class="form-control"
    :visible="visible"
    :range-presets="rangeCustom"
    @onChange="input"
  />
</template>

<script>
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import Dates from "@/utils/helpers/dates";

export default {
  name: "DatePickerRange",
  components: {
    VueDatePicker,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    rangeCustom: {
      type: Array,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    model: {
      start: new Dates().format(),
      end: new Dates().format(),
    },
  }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val.start) {
          this.model.start = val.start;
        }

        if (val.end) {
          this.model.end = val.end;
        }
      },
    },
  },
  methods: {
    input() {
      this.$emit("input", this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control ::v-deep .vd-picker__input {
  font-size: 14px;

  input {
    font-size: 14px !important;
  }
}
</style>
