<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <input
          v-model="filters.name"
          type="text"
          class="form-control"
          :placeholder="
            $t('message.search_for_x_followed_by_ellipses', {
              search: $t('label.modifier_lists'),
            })
          "
          autofocus="autofocus"
          data-test="name"
        />
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <select
          v-model="filters.selection_type"
          class="form-control formSelect"
          data-test="selectionType"
        >
          <option :value="null">{{ $t("label.all_types") }}</option>
          <option value="single">{{ $t("label.single") }}</option>
          <option value="multiple">{{ $t("label.multiple") }}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <button class="btn btn-outline-dark" type="submit">
          {{ $t("label.search") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
export default {
  name: "Filters",
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.filters.selection_type = null;
  },
};
</script>
