<template>
  <Multiselect
    v-model="model"
    :options="options"
    label="value"
    :multiple="true"
    :close-on-select="false"
    :allow-empty="true"
    track-by="key"
    selected-label="✓"
    deselect-label="✓"
    select-label=""
    :placeholder="$t('label.type')"
    @input="input"
  >
    <template slot="selection" slot-scope="{ values, isOpen }">
      <span v-if="values.length && !isOpen" class="multiselect__single">
        {{ label }}
      </span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import { pluck, isNil, join, filter } from "ramda";

export default {
  name: "SelectOrderingService",
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    options() {
      return [
        { key: "delivery", value: this.$t("label.delivery") },
        { key: "pickup", value: this.$t("label.pickup") },
        { key: "dine-in", value: this.$t("label.dine_in") },
      ];
    },
    label() {
      if (isNil(this.model)) return null;
      return join(", ", pluck("value", this.model));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (isNil(val)) {
          this.model = null;
          return;
        }

        this.model = filter((obj) => val.includes(obj.key), this.options);
      },
    },
  },
  methods: {
    input(value) {
      this.$emit("input", pluck("key", value));
    },
  },
};
</script>
