import Entity from "./listing/Entity";
import { is, has } from "ramda";

/** Class Builder to genearte Listing Resource components */
export default class ListingBuilder {
  /** Class constructor */
  constructor() {
    this.props = {};
  }

  /**
   * Set the page title to be shown in the top bar.
   *
   * @param {String} title
   * @returns {ListingBuilder}
   */
  setTitle(title) {
    if (!is(String, title)) {
      throw TypeError("Title accepts only string.");
    }

    this.props.title = title;
    return this;
  }

  /**
   * Vue component to be placed as table header in the listing.
   *
   * @param {Object} header Vue instance {Single File Component}
   * @returns {ListingBuilder}
   */
  setTableHeader(header) {
    if (!this._isVueComponentObject(header)) {
      throw TypeError("Table header should be a Vue Component object.");
    }

    this.props.tableHeader = header;
    return this;
  }

  /**
   * Vue component to be interated and generate the listing records.
   *
   * @param {Object} line Vue instance {Single File Component}
   * @returns {ListingBuilder}
   */
  setTableLine(line) {
    if (!this._isVueComponentObject(line)) {
      throw TypeError("Table line should be a Vue Component object.");
    }

    this.props.tableLine = line;
    return this;
  }

  /**
   * Vue component with the available filters.
   * If not provided, no filters will be available.
   *
   * @param {Object} filters Vue instance {Single File Component}
   * @returns {ListingBuilder}
   */
  withFilters(filters) {
    if (!this._isVueComponentObject(filters)) {
      throw TypeError("Filters should be a Vue Component object.");
    }

    this.props.filtersEl = filters;
    return this;
  }

  /**
   * Vue component with the listing summary.
   * If not provided, no summary will be available.
   *
   * @param {Object} filters Vue instance {Single File Component}
   * @returns {ListingBuilder}
   */
  withSummary(summary) {
    if (!this._isVueComponentObject(summary)) {
      throw TypeError("Summary should be a Vue Component object.");
    }

    this.props.summaryEl = summary;
    return this;
  }

  withHelpToolTip(helptoolTip) {
    if (!this._isVueComponentObject(helptoolTip)) {
      throw TypeError("helptoolTip should be a Vue Component object.");
    }

    this.props.helpToolTipEl = helptoolTip;
    return this;
  }

  /**
   * Vue component with the actions.
   *
   * @param {Object} actions Vue instance {Single File Component}
   * @returns {ListingBuilder}
   */
  withActions(actions) {
    if (!this._isVueComponentObject(actions)) {
      throw TypeError("Actions should be a Vue Component object.");
    }

    this.props.actionsEl = actions;
    return this;
  }

  /**
   * Build the final Listing component.
   *
   * @returns {Object} Object with render function
   */
  build() {
    const props = this.props;

    this._validateProps();

    return {
      render(h) {
        return h(Entity, { props });
      },
    };
  }

  /**
   * Validate whether the required props has been set or not.
   *
   * @throws {Error}
   * @returns {void}
   */
  _validateProps() {
    if (!has("title", this.props)) {
      throw Error("Title missing.");
    }

    if (!has("tableHeader", this.props)) {
      throw Error(
        `Builder ${this.props.title}: Table header component is missing.`
      );
    }

    if (!has("tableLine", this.props)) {
      throw Error(
        `Builder ${this.props.title}: Table line component is missing.`
      );
    }
  }

  /**
   * Check if given object is a Vue instance.
   *
   * @param {Object} obj
   * @returns {Boolean}
   */
  _isVueComponentObject(obj) {
    return is(Object, obj) && has("render", obj);
  }
}
