<template>
  <tr>
    <td width="90" class="text-center">
      <template v-if="!entry.isModifier">
        <img
          v-if="entry.photo"
          data-test="photo"
          class="img-fluid rounded"
          :src="`${entry.photo}?fm=png&h=60&w=60&q=90&fit=crop&crop=center`"
        />
        <div
          v-else
          data-test="photoPlaceholder"
          class="placeholderImg placeholderImg__small rounded"
        >
          <UtensilsIconAlt />
        </div>
      </template>
      <div v-else>
        <MinusIcon data-test="photoModifier" class="w-10 fill_greyLight" />
      </div>
    </td>
    <td class="pl-0">
      <p class="mb-1">{{ entry.name }}</p>
      <small class="text-muted truncate truncate__lines--2">
        {{ entry.description }}
      </small>
    </td>
    <td class="text-center">
      <span data-test="type">
        {{ $t(entry.isModifier ? "label.modifier" : "label.item") }}
      </span>
    </td>
    <td class="text-center">
      <span data-test="source">
        {{ entry.source ? entry.source.name : "" }}
      </span>
    </td>
    <Money
      tag="td"
      class="text-right"
      :amount="entry.amount"
      :business-id="businessId"
      data-test="price"
    />
    <td class="text-center">
      {{ entry.createdAt | date("DD/MM/YYYY") }}
      <small>{{ entry.createdAt | date("HH:mm:ss") }}</small>
    </td>
    <td class="text-center">
      <router-link
        :to="{
          name: 'items.edit',
          params: { itemId: entry.id },
        }"
        data-test="edit"
        class="btn btn-outline-dark"
      >
        {{ $t("label.view") }}
      </router-link>
      <AtomIntegrationDisabledButton
        class="ml-2"
        kind="delete"
        :enabled="!canDelete"
        :has-menu-integration="!canDelete"
        :label="$t('label.delete').toString()"
      >
        <a
          href="#"
          data-test="delete"
          class="btn btn-outline-danger"
          @click.prevent="emitDelete"
        >
          {{ $t("label.delete") }}
        </a>
      </AtomIntegrationDisabledButton>
    </td>
  </tr>
</template>

<script>
import UtensilsIconAlt from "@/assets/svg/icons/utensils-alt.svg";
import MinusIcon from "@/assets/svg/icons/minus.svg";
import Money from "@/components/helpers/Money.vue";
import { date } from "@/utils/filters";
import { AtomIntegrationDisabledButton } from "@/v2/new-design-system";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";

export default {
  name: "ListLine",
  filters: {
    date,
  },
  components: {
    UtensilsIconAlt,
    MinusIcon,
    Money,
    AtomIntegrationDisabledButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canDelete() {
      return (
        this.entry?.source?.isEditable &&
        this.entry?.source?.id === MenuManagementSourceByIdEnum.CHATFOOD
      );
    },
  },
  methods: {
    emitDelete() {
      if (!this.canDelete) return;

      this.$emit("delete", {
        payload: {
          id: this.entry.id,
        },
        dialogOptions: {
          customMessage: `<strong>${this.entry.name}</strong> will be permanentely deleted and removed from existing menus.`,
        },
      });
    },
  },
};
</script>
