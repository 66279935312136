<template>
  <AtomButtonLink
    v-if="canSeeReportsPage"
    :class="css()"
    :type="AtomButtonLinkTypeEnum.SECONDARY"
    :route="{
      name: 'reports.index',
      params: businessId,
    }"
  >
    <AtomIcon name="reports" :size="21" :class="css('icon')" />
    {{ t("label.reports") }}
  </AtomButtonLink>
</template>

<script>
import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent } from "@vue/composition-api";
import {
  AtomButtonLink,
  AtomButtonLinkTypeEnum,
  AtomIcon,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { VIEW_REPORTS } from "@/utils/enums/permissions";
import { useStore } from "@/store";

const css = bemBuilder("order-history-actions");

export default defineComponent({
  name: "OrderHistoryActions",
  components: {
    AtomIcon,
    AtomButtonLink,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const canSeeReportsPage = computed(() => {
      const { getters } = useStore();
      const permissions = getters["auth/getPermissions"];
      return permissions.includes(VIEW_REPORTS);
    });

    return {
      t,
      css,
      AtomButtonLinkTypeEnum,
      canSeeReportsPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.order-history-actions {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: var(--space-7);
  }
}
</style>
