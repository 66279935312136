import { http, IHttpErrorResponse } from "../http";
import { IRepoErrors } from "../errors";

export class SyncOmnivoreMenuError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: SyncOmnivoreMenu");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function syncOmnivoreMenu(request: {
  businessId: string;
}): Promise<void> {
  try {
    await http.post(
      `api/v4/businesses/${request.businessId}/omnivore/push-menu`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IHttpErrorResponse = e.response || {};

    if (response.status === 429) {
      return;
    }

    throw new SyncOmnivoreMenuError(error);
  }
}
