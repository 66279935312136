<template>
  <Multiselect
    v-model="model"
    :options="options"
    label="value"
    :multiple="true"
    :close-on-select="false"
    :allow-empty="true"
    track-by="key"
    selected-label="✓"
    deselect-label="✓"
    select-label=""
    :placeholder="$t('label.order_status')"
    @input="input"
  >
    <template slot="selection" slot-scope="{ values, isOpen }">
      <span v-if="values.length && !isOpen" class="multiselect__single">
        {{ label }}
      </span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import { getOrderStatusList } from "@/utils/auth/helpers";
import { pluck, isNil, join, map, filter } from "ramda";
import { mapGetters } from "vuex";

export default {
  name: "SelectOrderStatus",
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    ...mapGetters({
      permissions: "auth/getPermissions",
    }),
    options() {
      const labelledOptions = {
        placed: "label.new",
        accepted: "label.in_kitchen",
        scheduled: "label.scheduled_order",
        ready: "label.in_route_ready",
        complete: "label.delivered",
        rejected: "label.rejected",
        cancelled: "label.cancelled",
      };

      return map(
        (opt) => ({
          key: opt,
          value: this.$t(labelledOptions[opt]),
        }),
        getOrderStatusList(this.permissions)
      );
    },
    label() {
      if (isNil(this.model)) return null;
      return join(", ", pluck("value", this.model));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (isNil(val)) {
          this.model = null;
          return;
        }

        this.model = filter((obj) => val.includes(obj.key), this.options);
      },
    },
  },
  methods: {
    input(value) {
      this.$emit("input", pluck("key", value));
    },
  },
};
</script>
