<template>
  <div class="relative">
    <LoadingZone :loading="loading" class="table-responsive">
      <table class="table table-bordered table-hover m-0">
        <thead class="thead-light">
          <slot name="header" />
        </thead>
        <tbody>
          <slot name="items" />
        </tbody>
      </table>
    </LoadingZone>
  </div>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone";

export default {
  name: "Table",
  components: {
    LoadingZone,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
