<template>
  <th class="sorter">
    <span @click="sort">
      <SortSVG v-if="sortedBy !== title" class="w-8 mr-2" />
      <CaretDownSVG
        v-if="sortedBy === title"
        class="w-8 d-inline-block mr-2"
        :style="{
          transform: sortQuery === title ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
      />
      <slot> Title not provided </slot>
    </span>
  </th>
</template>

<script>
import { propOr, head } from "ramda";
import SortSVG from "@/assets/svg/icons/sort.svg";
import CaretDownSVG from "@/assets/svg/icons/caret-down.svg";

export default {
  name: "TableSorter",
  components: {
    CaretDownSVG,
    SortSVG,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    sortQuery() {
      return propOr("", "sort", this.$route.query);
    },
    sortedBy() {
      return this.sortQuery.replace("-", "");
    },
    sortedDirection() {
      return head(this.sortQuery) === "-" ? "desc" : "asc";
    },
  },
  methods: {
    sort() {
      if (this.sortedBy === this.title) {
        const nextDirection = this.sortedDirection === "asc" ? "-" : "";
        return this.updateQueryString(`${nextDirection}${this.title}`);
      }

      return this.updateQueryString(this.title);
    },
    updateQueryString(sort) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...{ sort, page: 1 },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sorter {
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  span {
    display: inline-flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
