<template>
  <tr>
    <TableSorter title="name" colspan="2" class="minw-300">
      {{ $t("label.item") }}
    </TableSorter>
    <TableSorter title="isModifier" class="text-center minw-120 maxw-120">
      {{ $t("label.type") }}
    </TableSorter>
    <th class="text-center minw-120 maxw-120">
      {{ $t("label.source") }}
    </th>
    <TableSorter title="amount" class="text-right minw-140 maxw-140">
      {{ $t("label.price") }}
    </TableSorter>
    <TableSorter title="createdAt" class="text-center minw-140 maxw-140">
      {{ $t("label.created_at") }}
    </TableSorter>
    <th class="text-center minw-200 maxw-200">{{ $t("label.actions") }}</th>
  </tr>
</template>

<script>
import TableSorter from "@/builders/listing/TableSorter.vue";

export default {
  name: "ListHeader",
  components: {
    TableSorter,
  },
};
</script>
