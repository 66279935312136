<template>
  <div class="modifier-list__actions">
    <ArticleLink tag="modifier_lists" class="btn btn-rounded-light">
      <i18n path="message.learn_more_about_x">
        <span class="text-primary">{{ $t("label.modifier_lists") }}</span>
      </i18n>
    </ArticleLink>
    <AtomButtonLink
      :size="AtomButtonLinkSizeEnum.SMALL"
      :route="{ name: 'modifier-lists.create' }"
    >
      {{ $t("label.create") }}
    </AtomButtonLink>
  </div>
</template>

<script>
import ArticleLink from "@/components/helpers/ArticleLink.vue";
import { AtomButtonLink, AtomButtonLinkSizeEnum } from "@/v2/new-design-system";

export default {
  name: "Actions",
  components: {
    ArticleLink,
    AtomButtonLink,
  },
  data: () => ({
    AtomButtonLinkSizeEnum: AtomButtonLinkSizeEnum,
  }),
};
</script>

<style lang="scss" scoped>
.modifier-list__actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-7);
}
</style>
