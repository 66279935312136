<template>
  <Multiselect
    v-model="model"
    :options="compiledOutlets"
    :multiple="true"
    track-by="id"
    label="name"
    :close-on-select="false"
    :allow-empty="true"
    selected-label="✓"
    deselect-label="✓"
    select-label=""
    :placeholder="$t('label.outlets')"
    @input="input"
    @mouseover.native="fetchOutlets"
  >
    <template slot="selection" slot-scope="{ values, isOpen }">
      <span v-if="values.length && !isOpen" class="multiselect__single">
        {{ label }}
      </span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import globalStore from "@/store";
import { mapGetters } from "vuex";
import { pluck, isNil, filter, join, is } from "ramda";
import { fetchDeletedOutlets } from "@/v2/repo/fetch-deleted-outlets";

export default {
  name: "SelectOutlets",
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    model: null,
    fetchedData: false,
    dataFetchInProgress: false,
    deletedOutlets: [],
  }),
  computed: {
    ...mapGetters({
      outlets: "outlets/getData",
    }),
    options() {
      return filter(
        (outlet) => outlet.businessId === this.businessId,
        this.outlets
      );
    },
    compiledOutlets() {
      return [
        ...this.options.map((option) => ({ id: option.id, name: option.name })),
        ...this.deletedOutlets.map((option) => ({
          id: option.id,
          name: `${option.name} [deleted]`,
        })),
      ];
    },
    label() {
      if (isNil(this.model)) return null;
      return join(", ", pluck("name", this.model));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (isNil(val)) {
          this.model = null;
          return;
        }

        this.model = filter(
          (obj) => val.includes(obj.id),
          this.compiledOutlets
        );
      },
    },
    businessId() {
      this.input(null);
      this.model = null;
    },
  },
  methods: {
    input(value) {
      if (!is(Array, value)) {
        this.$emit("input", value);
        return;
      }

      this.$emit("input", pluck("id", value));
    },
    async fetchOutlets() {
      if (!this.fetchedData && !this.dataFetchInProgress) {
        this.dataFetchInProgress = true;
        globalStore.dispatch("outlets/fetch");

        try {
          this.deletedOutlets = await fetchDeletedOutlets(this.businessId);
        } catch {
          // TODO: put sentry monitor here
        }
        this.fetchedData = true;
        this.dataFetchInProgress = false;
      }
    },
  },
};
</script>
