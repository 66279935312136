import Api from "./index";
import ItemConstructor from "@/constructors/Item";
import PaginationConstructor from "@/constructors/Pagination";
import { map } from "ramda";

export default {
  /**
   * Get list of items.
   *
   * @param {String} businessId
   * @param {Object} params Params to be attached to the request
   * @returns {{data: Array<ItemConstructor>, pagination: PaginationConstructor}}
   */
  async get(businessId, params = {}) {
    params = ItemConstructor.toFilterPayload(params);

    const res = await Api.get(`/api/v3/businesses/${businessId}/items`, {
      params,
    });

    return {
      data: map((obj) => new ItemConstructor(obj), res.data),
      pagination: new PaginationConstructor(res.meta),
    };
  },

  /**
   * Fetch an item.
   *
   * @param {String} businessId
   * @param {Object} payload
   * @returns {ItemConstructor}
   */
  async find(businessId, itemId, params = {}) {
    const res = await Api.get(
      `/api/v3/businesses/${businessId}/items/${itemId}`,
      {
        params,
      }
    );

    return new ItemConstructor(res.data);
  },

  /**
   * Create an item.
   *
   * @param {String} businessId
   * @param {Object} payload
   * @returns {ItemConstructor} Created item
   */
  async create(businessId, payload) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/items`,
      payload
    );

    return new ItemConstructor(res.data);
  },

  /**
   * Update an item.
   *
   * @param {String} businessId
   * @param {Object} payload
   * @returns {ItemConstructor} Created item
   */
  async update(businessId, itemId, payload) {
    const res = await Api.put(
      `/api/v3/businesses/${businessId}/items/${itemId}`,
      payload
    );
    return new ItemConstructor(res.data);
  },

  /**
   * Delete a item based on the given id.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @returns {void}
   */
  async destroy(businessId, itemId) {
    await Api.delete(`/api/v3/businesses/${businessId}/items/${itemId}`);
  },

  /**
   * Upload a photo to the given item.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @param {FormData: { photo: Binary }} payload
   * @returns {String} Link to the uploaded image
   */
  async uploadPhoto(businessId, itemId, payload) {
    return await Api.post(
      `/api/v3/businesses/${businessId}/items/${itemId}/upload-photo`,
      payload
    );
  },

  /**
   * Remove photo from the given item.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @returns {void}
   */
  async removePhoto(businessId, itemId) {
    await Api.put(
      `/api/v3/businesses/${businessId}/items/${itemId}/remove-photo`
    );
  },

  /**
   * Attach a list of modifierLists to an item.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @param {Array<Number>} modifierListIds
   * @returns {ItemConstructor} Item with all its resources loaded
   */
  async attachModifierLists(businessId, itemId, modifierListIds) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/items/${itemId}/attach-modifier-lists`,
      { modifier_lists_ids: modifierListIds }
    );

    return new ItemConstructor(res.data);
  },

  /**
   * Detach a list of modifierLists from an item.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @param {Array<Number>} modifierListIds
   * @returns {ItemConstructor} Item with all its resources loaded
   */
  async detachModifierLists(businessId, itemId, modifierListIds) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/items/${itemId}/detach-modifier-lists`,
      { modifier_lists_ids: modifierListIds }
    );

    return new ItemConstructor(res.data);
  },

  /**
   * Sort modifier lists as per given list of ids.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @param {Array<Number>} modifierListIds
   * @returns {void} Item with all its resources loaded
   */
  async sortModifierLists(businessId, itemId, modifierListIds) {
    await Api.put(
      `/api/v3/businesses/${businessId}/items/${itemId}/reorder-modifier-lists`,
      { ordered_modifier_lists_ids: modifierListIds }
    );
  },

  /**
   * Override Modifier List Settings for an item.
   *
   * @param {String} businessId
   * @param {String} itemId
   * @param {Object} payload
   * @returns {void}
   */
  async overrideModifierList(businessId, itemId, payload) {
    await Api.post(
      `/api/v3/businesses/${businessId}/items/${itemId}/override-modifier-list`,
      payload
    );
  },

  /**
   * Import list of items based on the given CSV file.
   *
   * @param {String} businessId
   * @param {Binary} file
   * @returns {void}
   */
  async importFile(businessId, file) {
    const formData = new FormData();
    formData.append("file", file);

    await Api.post(
      `/api/v3/businesses/${businessId}/import-catalogue`,
      formData
    );
  },
};
