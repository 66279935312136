<template>
  <div>
    <div class="input-group mt-5">
      <div class="custom-file">
        <input
          id="fileUpload"
          ref="inputFile"
          type="file"
          class="upload d-none form-control"
          data-test-file
          @change.prevent="feedFile($event)"
        />
        <label for="fileUpload" class="form-control m-0">
          {{ $t("label.no_file_chosen") }}
        </label>
        <div class="input-group-prepend">
          <label class="input-group-text" for="fileUpload">
            {{ $t("label.choose_file") }}
          </label>
        </div>
      </div>
    </div>
    <div class="text-md-left my-3">
      <i18n path="c.items.download_example_csv">
        <a
          href="https://chatfood.s3.ap-south-1.amazonaws.com/assets/templates/import-items.csv"
          download="import-items.csv"
          type="text/csv"
        >
          {{ $t("c.items.sample_csv_file") }}
        </a>
      </i18n>
    </div>
    <div v-if="fileName" class="d-flex mt-3">
      <p class="txt_overflow" data-test-fileName>{{ fileName }}</p>
      <span
        class="btn btn-sm btn-danger"
        data-test-clearFile
        @click.prevent="clearFileField"
      >
        X
      </span>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import { path, endsWith, equals, toLower, trim, dropLast } from "ramda";
import { Toast } from "@/design-system";

export default {
  name: "SelectFile",
  data: () => ({
    fileName: null,
    fullValidHeader: ["name", "description", "type", "price", "photo"],
  }),
  methods: {
    feedFile(e) {
      const file = path(["target", "files", "0"], e);

      if (typeof file === "undefined" || !this.isCsv(file)) {
        new Toast().create({
          type: "error",
          text: this.$t("message.select_valid_csv"),
        });
        this.clearFileField();
        return;
      }

      Papa.parse(file, {
        header: true,
        complete: (results) => this.fileParsed(results, file),
      });
    },
    fileParsed(results, file) {
      const headers = results.meta.fields.map((obj) => {
        return toLower(trim(obj));
      });

      if (this.isValidFile(headers)) {
        this.fileName = file.name;
        return this.$emit("selected", file);
      }

      new Toast().create({
        type: "error",
        text: this.$t("c.items.import_wrong_fields"),
      });
      this.clearFileField();
    },
    isValidFile(fileHeaders) {
      return (
        equals(this.fullValidHeader, fileHeaders) ||
        equals(dropLast(1, this.fullValidHeader), fileHeaders)
      );
    },
    isCsv(file) {
      return endsWith(".csv", file.name);
    },
    clearFileField() {
      this.$refs.inputFile.value = "";
      this.clearData();
    },
    clearData() {
      this.fileName = null;
      this.$emit("selected", null);
    },
  },
};
</script>
