<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="row">
    <div class="col-12 col-sm-4 col-md-6 col-lg-3 col-xl-2 mb-3">
      <Input v-model="filters.orderUid" :placeholder="$t('label.id')" />
    </div>
    <div class="col-12 col-sm-8 col-md-6 col-lg-3 col-xl-2 mb-3">
      <Input
        v-model="filters.phoneNumber"
        :placeholder="$t('label.phone_number')"
      />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <SelectOutlets v-model="filters.outlets" :business-id="businessId" />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <DatePickerRange v-model="date" />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <SelectOrderStatus v-model="filters.status" />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <SelectOrderChannel v-model="filters.channels" />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <SelectOrderingServices v-model="filters.orderingServices" />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <SelectPaymentMethods v-model="filters.paymentMethods" />
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-3">
      <button type="submit" class="btn btn-outline-dark" data-test="search-btn">
        {{ $t("label.search") }}
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import SelectOutlets from "@/components/helpers/forms/SelectOutlets.vue";
import SelectOrderStatus from "@/components/helpers/forms/SelectOrderStatus.vue";
import SelectOrderChannel from "@/components/helpers/forms/SelectOrderChannel.vue";
import SelectOrderingServices from "@/components/helpers/forms/SelectOrderingServices.vue";
import SelectPaymentMethods from "@/components/helpers/forms/SelectPaymentMethods.vue";
import DatePickerRange from "@/components/helpers/forms/DatePickerRange.vue";
import dayjs from "dayjs";
import { isNil, pathOr } from "ramda";
import { Input } from "@/design-system";

export default {
  name: "Filters",
  components: {
    SelectOutlets,
    SelectOrderStatus,
    SelectOrderChannel,
    SelectOrderingServices,
    SelectPaymentMethods,
    DatePickerRange,
    Input,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    date: {
      start: null,
      end: null,
    },
  }),
  watch: {
    date: {
      handler(val) {
        if (isNil(val.start) || isNil(val.end)) return;

        this.filters.createdAt = [
          dayjs(val.start).startOf("day").toISOString(),
          dayjs(val.end).endOf("day").toISOString(),
        ];
      },
    },
  },
  created() {
    this.initDateRange();
  },
  methods: {
    initDateRange() {
      // Set the correct start date
      this.date.start = dayjs().startOf("day").toISOString();

      const startAt = pathOr(null, ["createdAt", "0"], this.filters);
      if (startAt) {
        this.date.start = dayjs(startAt);
      }

      // Set correct end date
      this.date.end = dayjs().endOf("day").toISOString();

      const endAt = pathOr(null, ["createdAt", "1"], this.filters);
      if (endAt) {
        this.date.end = dayjs(endAt);
      }
    },
  },
};
</script>
