<template>
  <tr>
    <TableSorter title="id" class="minw-140">
      {{ $t("label.id") }}
    </TableSorter>
    <TableSorter title="status" class="minw-110">
      {{ $t("label.status") }}
    </TableSorter>
    <th class="minw-150">{{ $t("label.customer") }}</th>
    <th class="minw-110">{{ $t("label.phone_n") }}</th>
    <TableSorter title="orderingService" class="minw-100">
      {{ $t("label.type") }}
    </TableSorter>
    <TableSorter title="paymentMethod" class="minw-150">
      {{ $t("label.payment_method") }}
    </TableSorter>
    <TableSorter title="total" class="minw-120">
      {{ $t("label.total") }}
    </TableSorter>
    <TableSorter title="channel" class="minw-140">
      {{ $t("label.channel") }}
    </TableSorter>
    <TableSorter title="createdAt" class="minw-130">
      {{ $t("label.ordered_at") }}
    </TableSorter>
    <TableSorter title="rating" class="minw-110">
      {{ $t("label.rating") }}
    </TableSorter>
  </tr>
</template>

<script>
import TableSorter from "@/builders/listing/TableSorter.vue";

export default {
  name: "ListHeader",
  components: {
    TableSorter,
  },
};
</script>
